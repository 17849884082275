<template>
    <Component :is="as">
        {{ date ? $d(dayjs(date).toDate(), format) : '-' }}
    </Component>
</template>

<script setup>
defineProps({
    as: {
        type: String,
        default: "span"
    },
    date: {
        type: String,
        required: true,
        default: null
    },
    format: {
        type: String,
        default: "dateShort"
    },
    formatForCurrentYear: {
        type: String,
        default: null
    }
})

const dayjs = useDayjs()

const useFormat = computed(() => {
    if (props.formatForCurrentYear && dayjs(props.date).year() === dayjs().year()) {
        return props.formatForCurrentYear
    }

    return props.format
})

</script>

<style scoped>

</style>